import "./css/login.css";
import React, { ChangeEvent, useEffect, useState } from "react";
import Icon from "../../../../Icons/Icon";

import InputTextFieldWithLabel from "../../../molecules/InputTextFieldWithLabel";
import Button from "../../../atoms/Button";
import LinkCustom from "../../../atoms/Link";
// import { t } from "../../../common/atoms/translation";
import IconWithTitle from "../../../molecules/IconWithTitle";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import * as ROUTE from "../../../../routes/RouteConstants";
// import {t , translate } from "../CentralDataMangement/translation/Translation";

import { ApiCall } from "../../../../services/ApiServices";
import { PERSON_LOGIN } from "../../../../routes/ApiEndpoints";
import CustomNotify from "../../../atoms/CustomNotify";
import {
  MYMMO_MOBILE_APP,
  MYMMO_SERVICE_MICROSERVICE,

} from "../../../../Constants";

type OptionType = { label: string; value: string };

interface LoginState {
	email: string;
	password: string;
}

const Login: React.FC = () => {
	const navigator = useNavigate();

	const [state, setState] = useState<LoginState>({
		email: '',
		password: ''
	});

	useEffect(() => {
		const showPasswordCheckbox = document?.getElementById(
			"showPassword"
		) as HTMLInputElement;
		const passwordInput = document?.getElementById(
			"password"
		) as HTMLInputElement;

		if (showPasswordCheckbox && passwordInput) {
			// Define the event handler function as an arrow function
			const togglePasswordVisibility = () => {
				if (showPasswordCheckbox?.checked) {
					passwordInput.type = "text";
				} else {
					passwordInput.type = "password";
				}
			};

			// Add the event listener
			showPasswordCheckbox?.addEventListener("click", togglePasswordVisibility);

			// Clean up the event listener on component unmount
			return () => {
				showPasswordCheckbox?.removeEventListener(
					"click",
					togglePasswordVisibility
				);
			};
		}
	}, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount


	const validate = () => {
		return state?.email !== '' && state?.password !== ''
	}

	const handleLoginClick = async () => {
		let isValidated = validate();

		if (isValidated) {

		const requestData = {
			email:  state?.email,
			password: state?.password,
		};
		console.log(requestData)
		const response = await ApiCall.service(
		PERSON_LOGIN
		,
			"POST",
			requestData,
			true,
			MYMMO_SERVICE_MICROSERVICE
		);


			if (response.status === 200) {
				let data = response?.data;
				let userData  = data?.user;
			await 	CustomNotify({
					type: 'success',
					message: (response?.message)
				})
				await	secureLocalStorage.setItem('user_id',userData.id);
				await	secureLocalStorage.setItem('user_data',userData);
				await	navigator('/dashboard');
				

			} else {
				CustomNotify({
					type: 'error',
					message: ('Inavalid credentials')
				})
			}
		} else {
			CustomNotify({
				type: 'error',
				message: ('The email and password fields are required')
			})
		}
	}



	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e?.target;

		setState((prevState: LoginState) => ({
			...prevState,
			[name]: value
		}));
	}

	return (
		<div className="container-fluid">
			<div className="loginWrapper">
				<div className="row gx-5">
					<div className="col-4">

					</div>
					<div className="col-4 loginMiddleSection">
						<div className="card border-0 loginCard h-100 rounded-0">
							<div className="loginCardPadding">
								<div className="text-center">
									<Icon
										name="mymmo"
										width="15vw"
										height="3vw"
										className="img-fluid mx-auto"
										isIcon={true}
									/>
								</div>
								<div className="loginMiddleSectionContent text-center">
									<div className="colorSkyBlue loginInText">
										{("Log in to Mymmo-web")}
									</div>
									<div className="row">
										<InputTextFieldWithLabel
											placeholder={("Email address")}
											type="email"
											value={state?.email}
											name={"email"}
											id="emailId"
											className="mb-3 rounded-0"
											handleChange={handleChange}
										/>
										<InputTextFieldWithLabel
											placeholder={("Password")}
											type="password"
											value={state.password}
											name={"password"}
											id="password"
											className="mt-1 mb-2 rounded-0"
											handleChange={handleChange}
										/>
										<div className="text-start d-flex align-items-center mt-1">
											<input
												type="checkbox"
												id="showPassword"
												className="me-2 mt-0 form-check-input shadow-none"
											/>
											<label
												htmlFor="showPassword"
												className="showPasswordText"
											>
												{("Show password")}
											</label>
										</div>
									</div>
									<div className="row">
										<div className="col-12">
											<Button
												type="submit"
												title={("Login")}
												className="signInBtn w-100"
												handleClick={handleLoginClick}
											/>
										</div>


									</div>
								</div>
							</div>
							<hr
								style={{ margin: "0px", borderTop: "0.14vw solid #898989" }}
							/>

						</div>
					</div>

				</div>
			</div>
		</div>
	);
};

export default Login;
