import { Outlet } from "react-router-dom";
import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import secureLocalStorage from "react-secure-storage";
export const RootLayout = () => {
  const navigate = useNavigate();
let user = secureLocalStorage.getItem('user_id') ?? 0;
useEffect(()=>{
  if(!user) {
    navigate('/login');
  }
},[])
return (
    <div style={{ display: "flex" }}>
    <Sidebar />
    <div
      style={{ marginLeft: "9.8vw", width: "88.3vw", marginRight: "1.8vw" }}
      className="container-fluid"
    >
      <Outlet /> {/* Renders the matched child route components */}
    </div>
  </div>
)
}

export default RootLayout;
