import React from "react";
import Icon from "../../Icons/Icon";
import LabelField from "../atoms/LabelField";
import Link from "../atoms/Link";
import { Nav } from "react-bootstrap";
import IconWithTitle from "../molecules/IconWithTitle";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
interface HeaderProps {
  headerName?: string;
  headerClassName?: string;
}

const Header: React.FC<HeaderProps> = ({ headerName, headerClassName }) => {
  const navigator = useNavigate();
  const profileDropdownIconProps = {
    isIcon: true,
    isTitle: true,
    borderRadius: "50%",
    width: "2vw",
    height: "2vw",
    iconImageWrapperClassName: "text-center d-flex align-items-center",
    iconBackgroundColor: "#EEECF3",
    className: "profileDropdownMenuIcon",
    fillColor: "#8571b9",
    labelClassName:
      "colorPrimary companyName mobileFont cursor-pointer text-break",
    titleImageWrapperClassName: "text-center ms-2",
  };
  const handleLogout = async () => {
    // const response = await ApiCall?.getService(
    //   LOGOUT,
    //   "GET",
    //   CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    // );
    //
    // dispatch(removeUserDetails());
    await secureLocalStorage.removeItem('user_id');
    await secureLocalStorage.removeItem('user_data');
    await navigator("/login");
  };

  return (
    <>
      <div
        className={`pb-4 ${"headerWrapper my-4"}`}
      >

        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-end">
            <Link href="/">
              <Icon name="mymmo" width="13vw" height="2vw" isIcon={true} />
            </Link>
          </div>
          <div className="pe-5 me-4">
            <LabelField
              title={headerName}
              className={`colorPrimary poppins-semibold pe-5 me-5 ${headerClassName}`}
            />
          </div>
          <div className="d-inline-flex align-items-center py-2">
            <Nav>
              <span className="cursor-pointer secondaryColorHoverEffect me-3">
              </span>
              <div className=" d-flex align-items-center mb-3 mb-lg-0 me-lg-3" onClick={handleLogout}>
                <IconWithTitle
                  name="logoutIcon"
                  title={"Logout"}
                  {...profileDropdownIconProps}
                />

              </div>
            </Nav>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
