import React, { ReactElement, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { WELCOME_DSHBOARD } from "./RouteConstants";
import secureLocalStorage from "react-secure-storage";
interface PublicRouteProps {
  element: ReactElement;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ element }) => {
  const navigate = useNavigate();
let user = secureLocalStorage.getItem('user_id') ?? 0;
  useEffect(() => {
    if (user) {
      navigate(WELCOME_DSHBOARD);
    } else{
      navigate("/login");
    }
  }, [user, navigate]);

  return user ? null : element;

};

export default PublicRoute;
